<template>
    <div class="h-100 p-relative">
        <div v-if="true" class="qf-search-filters">
            <div class="card p-2 m-0">
                <validation-observer :ref="'companyValidation' + companyId">

                    <b-form-group label="Company Name" class="pb-1">
                        <template #label>
                            Company Name:<sup class="text-danger">*</sup>
                        </template>
                        <validation-provider
                            v-slot="{ errors }"
                            name="company name"
                            rules="required"
                        >
                            <b-form-input name="name"
                                          v-model="form.companyName"
                                          placeholder="Your company name"
                                          :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="VAT Number:">
                        <div class="d-flex justify-content-lg-between align-items-center">
                            <b-form-input name="vatNumber"
                                          v-model="form.vatNumber"
                                          placeholder=""/>
                        </div>
                    </b-form-group>

                    <b-form-group label="Email:">
                        <div class="d-flex justify-content-lg-between align-items-center">
                            <b-form-input name="email"
                                          v-model="form.email"
                                          placeholder=""
                                          data-cy="companyEmail"/>
                        </div>
                    </b-form-group>

                    <b-form-group label="Address:">
                        <div class="d-flex justify-content-lg-between align-items-center">
                            <b-form-input name="address"
                                          v-model="form.address"
                                          placeholder=""/>
                        </div>
                    </b-form-group>

                  <b-form-group label="Enable Zoom Integration:">
                    <div class="custom-control custom-switch">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          id="zoomIntegrationToggle"
                          v-model="zoomToggle"
                      />
                      <label
                          class="custom-control-label"
                          for="zoomIntegrationToggle"
                      ></label>
                    </div>
                  </b-form-group>
                  <b-form-group v-if="zoomToggle" label="Zoom API Key:">
                    <div class="d-flex justify-content-lg-between align-items-center">
                      <b-form-input name="zoomApiKey"
                                    v-model="form.zoomApiKey"
                                    placeholder=""/>
                    </div>
                    <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405" target="_blank">Need help?</a>
                  </b-form-group>

                  <b-form-group v-if="zoomToggle" label="Zoom Secret Key:">
                    <div class="d-flex justify-content-lg-between align-items-center">
                      <b-form-input name="zoomSecretKey"
                                    v-model="form.zoomSecretKey"
                                    placeholder=""/>
                    </div>
                    <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405" target="_blank">Need help?</a>
                  </b-form-group>
                  <b-form-group v-if="zoomToggle">
                    After generating the credentials above you can set up your webhook:
                    <ol >
                      <li>Go to <a class="font-weight-bold" href="https://marketplace.zoom.us/" target="_blank">https://marketplace.zoom.us/</a> and sign in</li>
                      <li>After you are signed in go to <a class="font-weight-bold" href="https://marketplace.zoom.us/user/build" target="_blank">https://marketplace.zoom.us/user/build</a></li>
                      <li>Choose the billing company for the current production</li>
                      <li>Click on "Feature" in the left vertical menu</li>
                      <li>Turn on "Event Subscriptions"</li>
                      <li>Click on "+ Add Event Subscription"</li>
                      <li>In the input field "Event notification endpoint URL" add: "{{ webhookUrl }}"</li>
                      <li>Then click on "+ Add Events" -> "Recording" -> "All Recordings have completed" -> "Done" </li>
                      <li>Click on "Save"</li>
                    </ol>
                  </b-form-group>

                    <b-form-group label="Logo:">
                        <b-form-file
                            v-model="companyLogo"
                            accept="image/*"
                        />
                    </b-form-group>
                </validation-observer>
                <div v-if="companiesLogo">
                    <div class="pt-1">
                        <b-img :src="companiesLogo" class="qf-logo"></b-img>
                    </div>
                    <b-button class="my-1" size="sm" variant="success" data-cy="submitCompany" @click="removeLogo">
                        Remove Logo
                    </b-button>
                </div>

                <b-button variant="primary" class="my-1" data-cy="submitCompany" @click="submitCompany()">
                    {{ saveLabel }}
                </b-button>
                <b-button
                    variant="outline-primary"
                    class="mb-1"
                    @click="cancelModal()"
                >CANCEL
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required,} from '@validations';
import {
    //BForm,
    BFormGroup,
    BFormInput,
    BImg,
    //BFormSelect,
    //BFormSelectOption,
    BButton,
    BFormFile,
    //BBadge,
    //BFormTextarea,
    //BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Parse from 'parse';
import store from "@/store";

export default {
    name: "ModalEditCompany",
    components: {
        //BForm,
        // VBToggle,
        BFormGroup,
        BImg,
        //BFormSelect,
        //BFormSelectOption,
        BButton,
        //BBadge,
        BFormFile,
      BFormInput,
        //BFormTextarea,
        //VueAutosuggest,
        ValidationProvider,
        ValidationObserver
        //BAvatar,

    },
    props: {
        companyToEdit: {
            type: Object,
            required: false,
            default: function () {
                return {}
            },
        }
    },
    data() {
        return {
            required,
          zoomToggle: false,
            form: {
                companyName: "",
                vatNumber: "",
                email: "",
                address: "",
                zoomApiKey: "",
                zoomSecretKey: ""
            },
            companyLogo: null,
            companyId: null,
            currentUser: null,
            curUserId: null,
            previewImgSrc: false,
            webhookUrl: process.env.VUE_APP_API_URL + "/zoom",
        }
    },

    mounted() {
        if (this.companyToEdit && this.companyToEdit.attributes) {
            this.form = JSON.parse(JSON.stringify(this.companyToEdit.attributes));
            this.zoomToggle = this.form.zoomApiKey && this.form.zoomSecretKey;
            this.companyId = this.companyToEdit.id;
        }
        let currentUser = Parse.User.current();
        if (currentUser) {
            this.currentUser = currentUser;
            this.curUserId = currentUser.id;
        }
        this.previewImgSrc = this.companyCoverUrl();
    },
    computed: {
        saveLabel() {
            if (this.companyToEdit.id) {
                return "SAVE CHANGES";
            } else {
                return "ADD COMPANY"
            }
        },
        companiesLogo() {
            if (this.companyLogo) {
                let selectedFile = this.companyLogo;
                return URL.createObjectURL(selectedFile);
            } else if (this.previewImgSrc) {
                return this.previewImgSrc;
            } else {
                return false;
            }
        },


    },
    methods: {
      collapseZoom(){
        this.myCollapse = !this.myCollapse;
      },
        companyCoverUrl() {
            let companyImg = false;
            if (this.companyToEdit && this.companyToEdit.attributes && this.companyToEdit.attributes.companyLogo) {
                companyImg = this.companyToEdit.attributes.companyLogo._url;
            }
            return companyImg;
        },
        removeLogo() {
            this.companyToEdit.unset('companyLogo');
            this.previewImgSrc = null;
        },

        companyLogoUrl(company) {
            let companyL = this.defaultLogo('svg');
            if (company && company.attributes.companyLogo) {
                companyL = company.attributes.companyLogo._url;
            }
            return companyL;
        },

        cancelModal() {
            this.$root.$emit('bv::toggle::collapse', 'cu-company');
            // this.form = {
            //     companyName: "",
            //     vatNumber: "",
            //     email: "",
            //     address: "",
            // };
        },
        submitCompany() {
            let self = this;
            this.$refs[`companyValidation` + this.companyId].validate().then(success => {
                if (success) {
                    console.log('success');
                    self.saveCompany();
                }
            }).catch(function (err) {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Check all fields for valid data!',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
                console.log('validation errors', err);
            });
        },
        saveCompany() {
            let self = this;
            let compObj = Parse.Object.extend('BillingCompany');
            let BillingCompany = new compObj();

            const userPointer = {"__type": "Pointer", "className": "_User", "objectId": this.curUserId};

            if (this.companyToEdit.id) {
                BillingCompany.set('objectId', this.companyToEdit.id)
            }

            BillingCompany.set('companyName', this.form.companyName);
            BillingCompany.set('zoomApiKey', this.form.zoomApiKey);
            BillingCompany.set('zoomSecretKey', this.form.zoomSecretKey);
            BillingCompany.set('vatNumber', this.form.vatNumber);
            BillingCompany.set('email', this.form.email);
            BillingCompany.set('address', this.form.address);
            BillingCompany.set('owner', userPointer);

            if (this.companyLogo) {
                let compLogo = this.companyLogo;
                let parseFile = new Parse.File(compLogo.name, compLogo, compLogo.type);
                BillingCompany.set('companyLogo', parseFile)
            }

            BillingCompany.save().then(function (newComp) {
                console.log("New company:", newComp);
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Company saved!',
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                });

                if (!self.companyToEdit.id) {
                    store.commit('app/SET_ADD_COMPANY', newComp);
                    store.commit('app/UPDATE_COMPANIES', newComp);
                } else {
                    store.commit('app/UPDATE_COMPANIES', newComp);
                }
                self.previewImgSrc = self.companyCoverUrl();

                self.cancelModal();

            }).catch(function (err) {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: err.message,
                        icon: 'CloseIcon',
                        variant: 'danger',
                    },
                });
                console.log("Save error: ", err.message);
            });

        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                console.log("on text change ", text)
            }
        },
    },
    watch: {
        companyToEdit: {
            immediate: true,
            handler(newCompanyToEdit) {
                if (newCompanyToEdit && newCompanyToEdit.attributes) {
                    this.companyId = newCompanyToEdit.id;
                    this.form = JSON.parse(JSON.stringify(newCompanyToEdit.attributes));
                } else {
                    this.companyId = null;
                    this.form = {
                        companyName: "",
                        vatNumber: "",
                        email: "",
                        address: "",
                    }
                    this.companyLogo = null;
                }
            }
        },
    }
}
</script>

<style scoped>
    #zoomIntegration{
      background-color: #0d6aad;
    }
    .qf-logo{
        width: 30%;
    }
</style>
